$(document).ready(function () {
    if ($('#list_stores').length == 0) {
        return;
    }
    var stores = [];

    $('.stores li').each(function(i) {
        stores.push({
            id: $(this).data('get-pos'),
            name: $(this).data('name'),
            lat: parseFloat($(this).data('lat')),
            lng: parseFloat($(this).data('lng'))
        });
    });
    var storesJson = JSON.stringify(stores);

    var radius = 30000; // Rayon en mètres
    var lat = parseFloat(document.getElementById('map').dataset.lat);
    var lng = parseFloat(document.getElementById('map').dataset.lng);
    var markers = JSON.parse(storesJson);

    function initMap() {
        var map = new google.maps.Map(document.getElementById('map'), {
            center: { lat: lat, lng: lng },
            zoom: 10
        });

        var customIcon = {
            url: "data:image/svg+xml;base64,PCFET0NUWVBFIHN2ZyBQVUJMSUMgIi0vL1czQy8vRFREIFNWRyAxLjEvL0VOIiAiaHR0cDovL3d3dy53My5vcmcvR3JhcGhpY3MvU1ZHLzEuMS9EVEQvc3ZnMTEuZHRkIj4KDTwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4KPHN2ZyB3aWR0aD0iODAwcHgiIGhlaWdodD0iODAwcHgiIHZpZXdCb3g9Ii00IDAgMzYgMzYiIHZlcnNpb249IjEuMSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIiB4bWxuczp4bGluaz0iaHR0cDovL3d3dy53My5vcmcvMTk5OS94bGluayIgZmlsbD0iIzAwMDAwMCI+Cg08ZyBpZD0iU1ZHUmVwb19iZ0NhcnJpZXIiIHN0cm9rZS13aWR0aD0iMCIvPgoNPGcgaWQ9IlNWR1JlcG9fdHJhY2VyQ2FycmllciIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+Cg08ZyBpZD0iU1ZHUmVwb19pY29uQ2FycmllciI+IDwhLS0gVXBsb2FkZWQgdG86IFNWRyBSZXBvLCB3d3cuc3ZncmVwby5jb20sIFRyYW5zZm9ybWVkIGJ5OiBTVkcgUmVwbyBNaXhlciBUb29scyAtLT4gPHRpdGxlPm1hcC1tYXJrZXI8L3RpdGxlPiA8ZGVzYz5DcmVhdGVkIHdpdGggU2tldGNoLjwvZGVzYz4gPGRlZnM+IDwvZGVmcz4gPGcgaWQ9IlZpdmlkLkpTIiBzdHJva2U9Im5vbmUiIHN0cm9rZS13aWR0aD0iMSIgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj4gPGcgaWQ9IlZpdmlkLUljb25zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgtMTI1LjAwMDAwMCwgLTY0My4wMDAwMDApIj4gPGcgaWQ9Ikljb25zIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgzNy4wMDAwMDAsIDE2OS4wMDAwMDApIj4gPGcgaWQ9Im1hcC1tYXJrZXIiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDc4LjAwMDAwMCwgNDY4LjAwMDAwMCkiPiA8ZyB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxMC4wMDAwMDAsIDYuMDAwMDAwKSI+IDxwYXRoIGQ9Ik0xNCwwIEMyMS43MzIsMCAyOCw1LjY0MSAyOCwxMi42IEMyOCwyMy45NjMgMTQsMzYgMTQsMzYgQzE0LDM2IDAsMjQuMDY0IDAsMTIuNiBDMCw1LjY0MSA2LjI2OCwwIDE0LDAgWiIgaWQ9IlNoYXBlIiBmaWxsPSIjZTIwMDFhIj4gPC9wYXRoPiA8Y2lyY2xlIGlkPSJPdmFsIiBmaWxsPSIjRkZGRiIgZmlsbC1ydWxlPSJub256ZXJvIiBjeD0iMTQiIGN5PSIxNCIgcj0iNyI+IDwvY2lyY2xlPiA8L2c+IDwvZz4gPC9nPiA8L2c+IDwvZz4gPC9nPgoNPC9zdmc+",
            scaledSize: new google.maps.Size(40, 40),
            anchor: new google.maps.Point(20, 40)
        };

        new google.maps.Marker({
            position: { lat: lat, lng: lng },
            map: map,
            icon: customIcon,
            title: "Ma Position"
        });

        new google.maps.Circle({
            map: map,
            center: { lat: lat, lng: lng },
            radius: radius,
            fillColor: "#537dd5",
            fillOpacity: 0.1,
            strokeColor: "#537dd5",
            strokeWeight: 1
        });

        markers.forEach(function(point) {
            var marker = new google.maps.Marker({
                position: { lat: parseFloat(point.lat), lng: parseFloat(point.lng) },
                map: map,
                title: point.name
            });

            marker.addListener('click', function() {
                var storeElement = $('.stores li[data-get-pos="' + point.id + '"]');
                $('.stores').animate({
                    scrollTop: storeElement.offset().top - $('.stores').offset().top + $('.stores').scrollTop()
                }, 1000);
                $('.stores li').css('background-color', '');
                storeElement.css('background-color', 'rgba(83, 125, 213, 0.1)');
            });
        });
    }

    initMap();
});