import './front.scss';

import Splide from '@splidejs/splide';
import Cookies from 'js-cookie';
import '@splidejs/splide/dist/css/splide.min.css';
import '@splidejs/splide/dist/css/themes/splide-default.min.css';
import './map.js';
import './store.js';
import './components/quote.scss';
import './components/between.scss';
import './components/coupons.scss';

$(document).ready(function () {
    if (Cookies.get('bannertop')) {
        $('#bannertop_slide').remove();
        $('#header').addClass('nobannertop')
        $('#category_header_filter_top').addClass('nobannertopFilter')
    }
    if ($('#bannertop_slide').length > 0) {
        new Splide('#bannertop_slide', {
            direction: 'ttb',
            type: 'loop',
            height: '56px',
            arrows: false,
            pagination: false,
            autoplay: true,
            interval: 5000,
            pauseOnHover: true
        }).mount();
    }

    var $collapseElement = $('#hours');
    var $toggleButton = $('[data-target="#hours"] i');

    $collapseElement.on('show.bs.collapse', function () {
        $toggleButton.removeClass('icon-down-custom').addClass('icon-up-custom');
    });

    $collapseElement.on('hide.bs.collapse', function () {
        $toggleButton.removeClass('icon-up-custom').addClass('icon-down-custom');
    });

    /*$('.icon-custom-localiser').on('click', function () {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(function (position) {
                var latitude = position.coords.latitude;
                var longitude = position.coords.longitude;
                console.log('Latitude:', latitude, 'Longitude:', longitude);
                // You can now use the latitude and longitude values as needed
            }, function (error) {
                console.error('Error getting location:', error);
            });
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    });*/
});

$('#icon-custom-localiser').on('click', function () {
    const $output = $('#output');
    $output.hide();

    if (!navigator.geolocation) {
        $output.text("La géolocalisation n'est pas supportée par votre navigateur.").show();
        return;
    }

    navigator.geolocation.getCurrentPosition(
        function (position) {
            console.log(position);
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            $('#lat').val(latitude);
            $('#lng').val(longitude);

            const geocodingApiUrl = `https://api-adresse.data.gouv.fr/reverse/?lat=${latitude}&lon=${longitude}`;

            $.getJSON(geocodingApiUrl, function (data) {
                if (data.features && data.features.length > 0) {
                    const address = data.features[0].properties.label;
                    console.log(address);
                    $('.input_search').val(address);
                } else {
                    $('#output').text("Impossible de récupérer l'adresse complète.").show();
                }
            }).fail(function () {
                $('#output').text("Une erreur est survenue lors de la récupération des données.").show();
            });
        },
        function (error) {
            const $output = $('#output');
            const $content = $('#content_text');
            switch (error.code) {
                case error.PERMISSION_DENIED:
                    $content.text("Vous avez refusé la permission de géolocalisation.");
                    break;
                case error.POSITION_UNAVAILABLE:
                    $content.text("Les informations de position sont indisponibles.");
                    break;
                case error.TIMEOUT:
                    $content.text("La demande de géolocalisation a expiré.");
                    break;
                default:
                    $content.text("Une erreur inconnue est survenue.");
            }
            $output.show();
        }
    );
});


$('.close_element').on('click', function () {
    $(this).parent().fadeOut(500);
    $('#header').addClass('nobannertop');
    $('#category_header_filter_top').addClass('nobannertopFilter');
    Cookies.set('bannertop', true, {expires: 1, path: '/'});
});

$(window).scroll(function () {
    if ($('#bannertop_slide').length == 0) {
        return
    }
    var sticky = $('#bannertop_slide'),
        scroll = $(window).scrollTop();

    if (scroll >= 50) {
        sticky.addClass('fixed');
    } else {
        sticky.removeClass('fixed');
    }
});


$(document).ready(function () {
    function debounce(func, wait) {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    }

    function fetchSuggestions(query) {
        if (query.length > 2) {

            $.getJSON('https://nominatim.openstreetmap.org/search?format=json&addressdetails=1&countrycodes=fr&q=' + query, function (data) {
                var suggestions = data.map(function (item) {
                    return '<div class="suggestion-item" data-lat="' + item.lat + '" data-lon="' + item.lon + '">' + item.display_name + '</div>';
                });
                $('#suggestions').html(suggestions.join(''));
            });
        } else {
            $('#suggestions').empty();
        }
    }

    const debouncedFetchSuggestions = debounce(fetchSuggestions, 300);

    $('.input_search').on('input', function () {
        var query = $(this).val();
        debouncedFetchSuggestions(query);
    });

    $(document).on('click', '.suggestion-item', function () {
        $('.suggestion-item').removeClass('selected');
        $(this).addClass('selected');
        var address = $(this).text();
        var latitude = $(this).data('lat');
        var longitude = $(this).data('lon');
        $('.input_search').val(address);
        $('#lat').val(latitude);
        $('#lng').val(longitude);
        $('#suggestions').empty();
        console.log('Address:', address);
        console.log('Latitude:', latitude);
        console.log('Longitude:', longitude);
    });

    $('.grid-container:first .grid-item').on('click', function () {
        const $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', true);
        $('.grid-container:first .grid-item').removeClass('checked');
        $(this).addClass('checked');
    });

    $('.grid-container:last .grid-item').on('click', function () {
        const $radio = $(this).find('input[type="radio"]');
        $radio.prop('checked', true);
        $('.grid-container:last .grid-item').removeClass('checked');
        $(this).addClass('checked');
    });
});

function handleFormSubmit(formSelector, url, buttonSelector) {
    let loader = $('.background_loader');
    $(formSelector).submit(function (event) {
        loader.show();

        event.preventDefault();

        var formData = $(this).serialize();

        $.post(url, formData, function (data) {
            $('.alert').remove();

            var response = JSON.parse(data);
            var message = '';

            if (response.error) {
                message = '<div class="alert alert-danger" role="alert">' + response.message + '</div>';
            } else {
                message = '<div class="alert alert-success" role="alert">' + response.message + '</div>';
                $(formSelector + ' .form_input input').val('');
                $(formSelector + ' .grid-container .grid-item').removeClass('checked');
                $(formSelector)[0].reset();
            }

            $(buttonSelector).before(message);
            loader.hide();
        });
    });
}

handleFormSubmit('#quote_form', quotation_url, 'button[name="submitQuote"]');
handleFormSubmit('#between_form_action', between_url, 'button[name="submitBetweenQuote"]');

$(document).ready(function () {
    $('.btn.generate-qr').on('click', function () {
        let loader = $('.background_loader');
        var couponId = $(this).data('coupon-id');
        var button = $(this);

        if (couponId) {
            loader.show();
            $.ajax({
                url: get_url_qr_code,
                data: {
                    couponId: couponId
                },
                method: 'GET',
                dataType: 'json',
                success: function (data) {
                    if (!data.error) {
                        var qrCodeDiv = $('#qrCodeModal .qr-code');
                        qrCodeDiv.html('<img src="' + data.qrCode + '" alt="QR Code">');
                        $('#qrCodeModal').modal('show');
                    } else {
                        alert('Failed to generate QR code');
                    }
                    loader.hide();
                },
                error: function () {
                    console.error('Error:', error);
                    loader.hide();
                }
            });
        }
    });
});